import React, { useEffect } from "react";
import { Layout } from "../components/layout";
import { Header } from "../components/header";
import { useStaticQuery, graphql } from "gatsby";
import RTF, { RTFDiv } from "../components/rtf";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import { MaxWidth, maxMedia, minMedia } from "../utils/style";
import { EasyGrid } from "../components/styles/EasyGrid";
import { CaptionText } from "../components/presse-kontakt";
import { Form } from "../components/contact-form";
import { Button } from "../components/styles/LinkButton";
import { useObservable, Observer } from "mobx-react-lite";
import _ from "lodash";
import { JoinComponents } from "../utils/ReactUtils";

declare namespace foo {
  export interface IRootObject {
    data: IData;
  }
  export interface IData {
    allContentfulBook?: IAllContentfulBook;
    uri?: string;
  }
  export interface IAllContentfulBook {
    nodes: INodesItem[];
  }
  export interface INodesItem {
    id: string;
    title: string;
    description: IDescription;
    orderLinks: IOrderLinksItem[] | null;
    priceList: IPriceListItem[];
    coverImage: ICoverImage;
  }
  export interface IDescription {
    json: IJson;
  }
  export interface IJson {
    data: IData;
    content: IContentItem[];
    nodeType: string;
  }
  export interface IContentItem {
    data: IData;
    content?: IContentItem[];
    nodeType: string;
    marks?: IMarksItem[];
    value?: string;
  }
  export interface IMarksItem {
    type: string;
  }
  export interface IOrderLinksItem {
    title: string;
    link: string;
  }
  export interface IPriceListItem {
    title: string;
    ISBN: string;
    preis: number;
  }
  export interface ICoverImage {
    fixed: IFixed;
  }
  export interface IFixed {
    src: string;
  }
}

const BestellListe = () => {
  const data: foo.IData = useStaticQuery(graphql`
    query {
      allContentfulBook(sort: { fields: order, order: ASC }) {
        nodes {
          id
          title
          description {
            json
          }
          orderLinks {
            title
            link
          }
          priceList {
            title
            ISBN
            preis
          }
          coverImage {
            fixed(width: 150) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  `);

  const tmpData = useObservable<{
    bookAmounts: any;
    email: string;
    note: string;
    isSubmitted: boolean;
  }>({ bookAmounts: {}, email: "", note: "", isSubmitted: false });

  useEffect(() => {
    console.log("foo");
    tmpData.bookAmounts = _.fromPairs(
      _.flatten(
        data.allContentfulBook.nodes.map(x =>
          x.priceList.map(y => [
            `${x.id}-${y.ISBN}`,
            { bookRef: x, priceRef: y, amount: 0 }
          ])
        )
      )
    );
  }, [data]);

  return (
    <Observer>
      {() => {
        const sumTotal = _.sum(_.map(tmpData.bookAmounts, (v, k) => +v.amount * v.priceRef.preis)).toFixed(2);

        const message = `Buchbestellung
=======

Von: ${tmpData.email || ""}
Notiz: 
${tmpData.note || "keine"}

--------

${_
  .filter(tmpData.bookAmounts, (v, k) => v.amount > 0)
  .map(
    (v, k) =>
      `${v.amount} × ${v.bookRef.title} \n\t${v.priceRef.title}, ISBN ${
        v.priceRef.ISBN
      } (${v.priceRef.preis}) \t\t\n\tEUR ${(
        +v.amount * +v.priceRef.preis
      ).toFixed(2)}`
  )
  .join("\n")}

Gesamtpreis: EUR ${sumTotal}
`;

        return (
          <Layout>
            <Header small>
              <div className="circle-text">
                <h1>Bücher bestellen</h1>
              </div>
              <div
                className="circle"
                style={{
                  backgroundImage: `url(${require("../images/jessica-ruscello-196422-unsplash.jpg")})`
                }}
              />
            </Header>
            {!tmpData.isSubmitted ? (
              <Form
                style={{ flexGrow: 1, paddingTop: "3rem" }}
                onSubmit={async e => {
                  e.preventDefault();

                  var formData = new FormData();

                  formData.append("email", tmpData.email);
                  formData.append("message", message);

                  tmpData.isSubmitted = true;

                  const res = await fetch(
                    "https://public.kom-neun.de/wn-api/book-order.php",
                    {
                      method: "POST",
                      body: formData
                    }
                  );

                  console.log(res);
                }}
              >
                <MainContent>
                  <h3 style={{ margin: "0 0 3rem 0", textAlign: "center" }}>
                    Bücherliste
                  </h3>

                  {data.allContentfulBook.nodes.map(x => {
                    return (
                      <BookEntry key={x.id}>
                        <GatsbyImage
                          style={{ minWidth: 150 }}
                          fixed={x.coverImage.fixed}
                        />

                        <div style={{ width: "100%" }}>
                          <h4>{x.title}</h4>
                          {/* <RTF content={x.description.json} /> */}
                          {x.priceList.map(y => (
                            <PriceEntry>
                              <EasyGrid vert className="description">
                                <div>{y.title}</div>
                                <CaptionText>ISBN: {y.ISBN}</CaptionText>
                              </EasyGrid>
                              <div className="price">
                                EUR {y.preis.toFixed(2)} ×
                              </div>

                              {tmpData.bookAmounts &&
                                tmpData.bookAmounts[`${x.id}-${y.ISBN}`] && (
                                  <>
                                    <div className="amount">
                                      <input
                                        value={
                                          tmpData.bookAmounts[
                                            `${x.id}-${y.ISBN}`
                                          ].amount
                                        }
                                        min={0}
                                        onChange={e => {
                                          tmpData.bookAmounts[
                                            `${x.id}-${y.ISBN}`
                                          ].amount = e.target.value;
                                        }}
                                        placeholder="Anzahl"
                                        type="number"
                                      />
                                    </div>
                                    <div className="sum">
                                      = EUR{" "}
                                      {(
                                        tmpData.bookAmounts[`${x.id}-${y.ISBN}`]
                                          .amount * y.preis
                                      ).toFixed(2)}
                                    </div>
                                  </>
                                )}
                            </PriceEntry>
                          ))}

                          {x.orderLinks && (
                            <RTFDiv style={{ marginTop: "1rem" }}>
                              <span style={{ marginRight: "0.5rem" }}>
                                Auch zu finden unter:{" "}
                              </span>
                              {JoinComponents(
                                x.orderLinks.map(x => (
                                  <a href={x.link}>{x.title}</a>
                                )),
                                " • "
                              )}
                            </RTFDiv>
                          )}
                        </div>
                      </BookEntry>
                    );
                  })}

                  <hr />

                  <div>
                    <h3 style={{ margin: "3rem 0", textAlign: "center" }}>
                      Kontaktdaten
                    </h3>

                    <label htmlFor="">Email:</label>
                    <input
                      type="email"
                      required
                      value={tmpData.email}
                      onChange={e => (tmpData.email = e.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="">Notiz:</label>
                    <textarea
                      value={tmpData.note}
                      onChange={e => (tmpData.note = e.target.value)}
                      style={{ height: 150 }}
                    />
                  </div>

                  <div>
                    <label htmlFor="">Generierte Nachricht:</label>
                    <textarea
                      readOnly
                      disabled
                      value={message}
                      style={{ height: 450, background: "#eee" }}
                    />
                  </div>

                  <div>
                    Wir werden uns nach Eingang Ihrer Bestellung mit Ihnen in
                    Verbindung setzen.
                  </div>

                  <div className="sum">
                    <Button>Bestellung abschicken</Button>
                  </div>
                </MainContent>
              </Form>
            ) : (
              <MaxWidth>
                <h3>Vielen Dank</h3>
                <p>Ihre Bestellung wurde erfolgreich abgeschickt.</p>
                <p>
                  Wir werden uns schnellstmöglich mit Ihnen in Verbindung
                  setzen.
                </p>
              </MaxWidth>
            )}
          </Layout>
        );
      }}
    </Observer>
  );
};

export default BestellListe;

const MainContent = styled(MaxWidth)`
  > .inner {
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const BookEntry = styled.div`
  width: 100%;
  display: flex;

  ${maxMedia.tablet`
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }

  `}

  ${minMedia.tablet`
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  `}
`;

const PriceEntry = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > .description {
    flex-grow: 1;
  }

  > .sum {
    width: 200px;
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }

  ${maxMedia.tablet`
    flex-direction: column;
    align-items: flex-start;;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }

  `}
`;
