import _ from "lodash";
import React from "react";

export const JoinComponents = (
  inputData: (JSX.Element | null)[],
  separator: string | JSX.Element = ","
) => {
  const data = inputData.filter(_.identity) as JSX.Element[];
  let counter = 0;

  if (data.length === 0) {
    return [];
  }
  if (data.length === 1) {
    return data;
  }

  return data
    .reduce((prev: (JSX.Element | string)[], curr: JSX.Element) => {
      counter += 1;
      return prev.concat([
        curr,
        <React.Fragment key={counter}>{separator}</React.Fragment>
      ]);
    }, [])
    .slice(0, -1);
};

export const getUniqueKey = () => Math.random();
